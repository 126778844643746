// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy-container {
    background-color: #000; /* Black background */
    color: #fff; /* White text for contrast */
    margin: 3%;
  }
  
  .privacy-section {
    padding: 20px 0;
    border-bottom: 1px solid #333; /* Subtle separator between sections */

  }
  
  .privacy-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .privacy-section p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  .parallax-effect {
    transition: transform 0.5s ease-out;
  }
  
  /* Make it responsive */
  @media (max-width: 768px) {
    .privacy-section h2 {
      font-size: 1.2rem;
    }
  
    .privacy-section p {
      font-size: .8rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Privacy_policy/privacy_policy.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB,EAAE,qBAAqB;IAC7C,WAAW,EAAE,4BAA4B;IACzC,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,6BAA6B,EAAE,sCAAsC;;EAEvE;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,mCAAmC;EACrC;;EAEA,uBAAuB;EACvB;IACE;MACE,iBAAiB;IACnB;;IAEA;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".privacy-policy-container {\r\n    background-color: #000; /* Black background */\r\n    color: #fff; /* White text for contrast */\r\n    margin: 3%;\r\n  }\r\n  \r\n  .privacy-section {\r\n    padding: 20px 0;\r\n    border-bottom: 1px solid #333; /* Subtle separator between sections */\r\n\r\n  }\r\n  \r\n  .privacy-section h2 {\r\n    font-size: 2.5rem;\r\n    margin-bottom: 20px;\r\n    text-transform: uppercase;\r\n    letter-spacing: 2px;\r\n  }\r\n  \r\n  .privacy-section p {\r\n    font-size: 1.2rem;\r\n    line-height: 1.6;\r\n  }\r\n  \r\n  .parallax-effect {\r\n    transition: transform 0.5s ease-out;\r\n  }\r\n  \r\n  /* Make it responsive */\r\n  @media (max-width: 768px) {\r\n    .privacy-section h2 {\r\n      font-size: 1.2rem;\r\n    }\r\n  \r\n    .privacy-section p {\r\n      font-size: .8rem;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
