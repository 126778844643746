// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.our-features-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: black;
    padding: 20px;
  }
  
  .our-features-card {
    background-color: #3855cb;
    color: white;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    text-align: center;
    width: 200px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .our-features-card:hover {
    transform: perspective(600px) rotateY(10deg) rotateX(10deg);
  }
  
  .our-features-icon {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .our-features-text {
    font-size: 14px;
  }
  
  @media (max-width: 768px) {
    .our-features-card {
      width: 150px;
      height: 80px;
      margin: 5px;
    }
  }
  
  @media (max-width: 480px) {
    .our-features-card {
      width: 120px;
      height: 70px;
      margin: 5px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Features/FeatureCards.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;IACf,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,0BAA0B;IAC1B,eAAe;IACf,wCAAwC;EAC1C;;EAEA;IACE,2DAA2D;EAC7D;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE;MACE,YAAY;MACZ,YAAY;MACZ,WAAW;IACb;EACF;;EAEA;IACE;MACE,YAAY;MACZ,YAAY;MACZ,WAAW;IACb;EACF","sourcesContent":[".our-features-container {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    align-items: center;\r\n    flex-wrap: wrap;\r\n    background-color: black;\r\n    padding: 20px;\r\n  }\r\n  \r\n  .our-features-card {\r\n    background-color: #3855cb;\r\n    color: white;\r\n    padding: 20px;\r\n    margin: 10px;\r\n    border-radius: 10px;\r\n    text-align: center;\r\n    width: 200px;\r\n    height: 100px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    transition: transform 0.3s;\r\n    cursor: pointer;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\r\n  }\r\n  \r\n  .our-features-card:hover {\r\n    transform: perspective(600px) rotateY(10deg) rotateX(10deg);\r\n  }\r\n  \r\n  .our-features-icon {\r\n    font-size: 24px;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .our-features-text {\r\n    font-size: 14px;\r\n  }\r\n  \r\n  @media (max-width: 768px) {\r\n    .our-features-card {\r\n      width: 150px;\r\n      height: 80px;\r\n      margin: 5px;\r\n    }\r\n  }\r\n  \r\n  @media (max-width: 480px) {\r\n    .our-features-card {\r\n      width: 120px;\r\n      height: 70px;\r\n      margin: 5px;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
