// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
    min-height: 100vh;
    width: 100vw;
    padding: 20px 50px 50px 50px;
    background: black;
    color: rgba(255, 255, 255, 0.8);
    
  }
  .about h1
  {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 4rem;
    font-style: oblique;
  }
  .about p
  {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    font-style: oblique;
  }
  
  iframe {
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  iframe:last-child {
    padding-right: 20px;
  }
  
  .videos {
    transform: translateX(-50px);
    display: flex;
    overflow-x: hidden;
    width: 100vw;
    background-color: #990000;
    background-image: linear-gradient(147deg, #990000 0%, #ff0000 74%);
  }
  
  .videos::-webkit-scrollbar {
    height: 10px;
    outline: none;
  }
  
  .videos::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: white;
  }
  
  @media (max-width: 500px) {
    .videos::-webkit-scrollbar {
      display: none;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Aboutus/aboutus_page.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,4BAA4B;IAC5B,iBAAiB;IACjB,+BAA+B;;EAEjC;EACA;;IAEE,wEAAwE;IACxE,eAAe;IACf,mBAAmB;EACrB;EACA;;IAEE,4DAA4D;IAC5D,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,4BAA4B;IAC5B,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,kEAAkE;EACpE;;EAEA;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE;MACE,aAAa;IACf;EACF","sourcesContent":[".about {\r\n    min-height: 100vh;\r\n    width: 100vw;\r\n    padding: 20px 50px 50px 50px;\r\n    background: black;\r\n    color: rgba(255, 255, 255, 0.8);\r\n    \r\n  }\r\n  .about h1\r\n  {\r\n    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;\r\n    font-size: 4rem;\r\n    font-style: oblique;\r\n  }\r\n  .about p\r\n  {\r\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    font-size: 1.2rem;\r\n    font-style: oblique;\r\n  }\r\n  \r\n  iframe {\r\n    margin-left: 20px;\r\n    margin-bottom: 20px;\r\n    margin-top: 20px;\r\n  }\r\n  \r\n  iframe:last-child {\r\n    padding-right: 20px;\r\n  }\r\n  \r\n  .videos {\r\n    transform: translateX(-50px);\r\n    display: flex;\r\n    overflow-x: hidden;\r\n    width: 100vw;\r\n    background-color: #990000;\r\n    background-image: linear-gradient(147deg, #990000 0%, #ff0000 74%);\r\n  }\r\n  \r\n  .videos::-webkit-scrollbar {\r\n    height: 10px;\r\n    outline: none;\r\n  }\r\n  \r\n  .videos::-webkit-scrollbar-thumb {\r\n    border-radius: 0;\r\n    background: white;\r\n  }\r\n  \r\n  @media (max-width: 500px) {\r\n    .videos::-webkit-scrollbar {\r\n      display: none;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
