// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.whatsapp-button
{
    margin: 3px;
}
.nav
{
    padding: 0px;
    border-bottom: 2px solid white;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/header.css"],"names":[],"mappings":";AACA;;IAEI,WAAW;AACf;AACA;;IAEI,YAAY;IACZ,8BAA8B;AAClC","sourcesContent":["\r\n.whatsapp-button\r\n{\r\n    margin: 3px;\r\n}\r\n.nav\r\n{\r\n    padding: 0px;\r\n    border-bottom: 2px solid white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
